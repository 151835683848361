<template>
  <v-col
      :cols="6"
      :xs="12"
      :sm="12"
      :md="6"
      :lg="6"
      :xl="$vuetify.breakpoint.width >= 2800 ? 2 : $vuetify.breakpoint.width >= 2000 ? 3 :  4" >
    <v-card id="card-trip" class="pa-0 elevation-4 " >
      <v-card-title class="trip-title py-1 pa-0">
        <v-row no-gutters>
          <v-col cols="8">
            <h4 class="secondary--text text-title-card" id="title-card">
              <v-icon class="icon-card secondary--text mr-2"> </v-icon>
              {{ $t("components.views.trip.no_offer_assign.card.trip_no") }} {{ dataProp.tripNumber }}
            </h4>
          </v-col>
          <v-col cols="4" align="right">
            <h4 class="green--text text--darken-2 moneytext pr-4">
              {{ formatMoney(dataProp.amount, dataProp.currency) }}
            </h4>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider  class="mb-1 custom-divider" />
      <v-card-text class="pl-0 py-2 pr-0">
        <v-container class="pa-0 ma-0">
          <v-row class="justify-center  card-route-noofferassign">

<!-- Columna 1: Route trip -->
<v-col cols="6" sm="6" md="6" lg="6" xl="6" class="py-2 pl-0 route-trip-container">
  <v-timeline align-top dense class="pa-0" >
    <v-timeline-item  color="secondary"  class="ma-0" small>
      <v-row>
          <v-col class="stop-item">
            <v-chip color="secondary" variant="flat" class="mb-2 card-chip">
              <p  class="ma-0 pa-0 chip-text">{{ $t("components.views.trip.no_offer_assign.card.origin") }}</p>
            </v-chip>
            <v-row no-gutters class="mt-2">
              <v-col cols="12" v-if="dataProp.origin">
                <p class="mb-1 text">
                  <b>
                    <v-icon class="primary--text mr-2" size="small">
                      fa-solid fa-map-marker-alt
                    </v-icon>
                    {{ $t("components.views.trip.no_offer_assign.card.city") }}:</b>
                    <span class="route-text">
                      {{ dataProp.origin.city }}
                    </span>
                </p>
                <p class="mb-1 text">
                  <b>
                    <v-icon class="primary--text mr-2" size="small">
                        fa-solid fa-map-marked-alt
                    </v-icon>
                    {{ $t("components.views.trip.no_offer_assign.card.address") }}:</b>
                    <span class="route-text">
                     {{ dataProp.origin.address }}
                    </span>
                </p>
                <p class="mb-1 text">
                  <b>
                    <v-icon class="primary--text mr-2" size="small">
                      fa-solid fa-calendar-alt
                    </v-icon>
                    {{ $t("components.views.trip.no_offer_assign.card.date") }}:</b>
                    <span class="route-text">
                      {{ dataProp.origin.schedule_date }}
                    </span>
                </p>
              </v-col>
            </v-row>
          </v-col>
          </v-row>
    </v-timeline-item>

    <v-timeline-item color="secondary" small>
      <v-row class="pt-1">
        <v-col class="stop-item">
          <v-chip color="secondary" variant="flat" class="mb-2 card-chip">
            <p  class="ma-0 pa-0 chip-text">
              {{ $t("components.views.trip.no_offer_assign.card.destiny") }}
            </p>
          </v-chip>
          <v-row no-gutters class="mt-2">
            <v-col cols="12" v-if="dataProp.destiny">
              <p class="mb-1 text">
                <b>
                  <v-icon class="primary--text mr-2" size="small">
                    fa-solid fa-map-marker-alt
                  </v-icon>
                    {{ $t("components.views.trip.no_offer_assign.card.city") }}:</b>
                  <span class="route-text">
                    {{ dataProp.destiny.city }}
                  </span>
              </p>
              <p class="mb-1 text">
                  <b>
                    <v-icon class="primary--text mr-2" size="small">
                        fa-solid fa-map-marked-alt
                    </v-icon>
                    {{ $t("components.views.trip.no_offer_assign.card.address") }}:</b>
                    <span class="route-text">
                      {{ dataProp.destiny.address }}
                    </span>
                </p>
                <p class="mb-1 text">
                  <b>
                    <v-icon class="primary--text mr-2" size="small">
                      fa-solid fa-calendar-alt
                    </v-icon>
                    {{ $t("components.views.trip.no_offer_assign.card.date") }}:</b>
                  <span class="route-text">
                    {{ dataProp.destiny.schedule_date }}
                  </span>
                </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-timeline-item>
  </v-timeline>
</v-col>
<!-- columna 2: info trip -->
<v-col cols="6" sm="6" md="6" lg="6" xl="6" class="mb-1 card-info-container" >
  <v-row class="justify-center py-0 mt-0 card-info-noofferasign" >
    <v-col cols="12" class="py-3 mt-0" >
      <!-- item -->
      <p class="text" >
        <b
        >{{ $t("components.views.trip.no_offer_assign.card.type_operation") }}:
        </b></br>
          <span class="route-text">
            {{ dataProp.nameTypeOperation }}
          </span>
      </p>
    </v-col>
  </v-row>
</v-col>
</v-row>
</v-container>
</v-card-text>
<v-divider  class="custom-divider" />

<v-card-actions class="card-btn-actions pa-0 ma-0">
    <v-container class="ma-0 pa-0">
        <v-row class="justify-center ma-0 pa-0 btn-container">
            <div  class="btn-offers">
              <v-btn
                variant="outlined"
                color="blue-darken-4"
                dense
                class="card-btn-nooffersasign"
                @click="onOpenModalOfferTrips()"
              >
              <v-icon class="mr-2" color="secondary">fa-solid fa-dollar-sign </v-icon>
                {{ $t("components.views.trip.no_offer_assign.card.offer") }}
              </v-btn>
            </div>
            <div  class="btn-assign">
              <v-btn
              variant="outlined"
                  color="blue-darken-4"
                  class="card-btn-nooffersasign"
                  dense
                  @click="onOpenModalAssign()"
                >
                  <v-icon class="mr-2" color="secondary">fa-solid fa-plus </v-icon>
                  {{ $t("components.views.trip.no_offer_assign.card.assign") }}
                </v-btn>
            </div>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialogAssign" width="600px" persistent>
      <ModalAssign
        @closeForm="onCloseModal"
        @reloadPage="onReloadPage"
        :key="index"
        :idMonitoringOrder="dataProp.idMonitoringOrder"
        />
    </v-dialog>

    <v-dialog v-model="dialogOfferTrips" width="900px" scrollable persistent>
      <ModalOfferTrips
        @closeForm="onCloseModal"
        @reloadPage="onReloadPage"
        :key="index"
        :idMonitoringOrder="dataProp.idMonitoringOrder"
      />
    </v-dialog>
  </v-col>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    ModalAssign: () =>
      import(
        /* webpackChunkName: "ModalAssign" */ "@/components/views/Trip/NoOfferAssign/ModalAssign.vue"
      ),
    ModalOfferTrips: () =>
      import(
        /* webpackChunkName: "ModalOfferTrips" */ "@/components/views/Trip/NoOfferAssign/modals/modalofferstrips/ModalOfferTrips.vue"
      ),
  },
  data() {
    return {
      index: 0,
      dialogAssign: false,
      dialogOfferTrips: false,
    };
  },
  computed: {
    dataProp: {
      get() {
        return this.data
          ? this.data
          : {
              tripNumber: "",
              origin: {
                city: "",
              },
              destiny: {
                city: "",
              },
            };
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    title() {
      let val = this.$vuetify.breakpoint.lg ? 10 : this.$vuetify.breakpoint.xl ? 20 : 10,
        title = this.dataProp.tripNumber;
      return title.length > val ? title.substr(0, val) + " …" : title;
    },
  },
  methods: {
    onReloadPage() {
      this.dialogOfferTrips = false;
      this.dialogAssign = false;
      this.$emit("reloadPage");
    },

    onOpenModalAssign() {
      this.dialogAssign = true;
      this.index += 1;
    },

    onOpenModalOfferTrips() {
      this.dialogOfferTrips = true;
      this.index += 1;
    },
    async onCloseModal() {
      if (
        await this.$root.$confirm(
          this.$t("components.views.trip.no_offer_assign.card.cancel_the_process"),
          this.$t("components.views.trip.no_offer_assign.card.are_you_sure_to_cancel_the_process"),
          "Si, Cancelar"
        )
      ) {
        this.dialogOfferTrips = false;
        this.dialogAssign = false;
      }
    },
  },
};
</script>


<style scoped>
@import './CardStyle.scss';

.v-timeline::before {
  background-color: #F05A24;
}

div.v-timeline-item__divider {
    position: relative;
    min-width: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F05A24;
}

.stop-item {
  padding: 1.2em 0 0 0;
  position: relative;
  left: -10px;
}

.card-height {
    min-height: 350px;
    height: auto;
  }

.card-chip {
    height: 18px;
}

.chip-text {
  font-size: 12px;
}

 /* Pantallas  grandes (1300px o más) */
 @media (min-width: 1300px) {
      .chip-text {
        font-size: 15px;
      }
      .card-chip {
        height: 20px;
      }

    }

    /* Pantallas extra grandes (1600px o más) */
    @media (min-width: 1600px) {
      .chip-text {
        font-size: 15px;
      }
      .card-chip {
        height: 20px;
      }

    }


</style>


<template>
  <v-col
      :cols="6"
      :xs="12"
      :sm="12"
      :md="6"
      :lg="6"
      :xl="$vuetify.breakpoint.width >= 2800 ? 2 : $vuetify.breakpoint.width >= 2000 ? 3 :  4" >
    <v-card id="card-trip" class="pa-0 pb-0 elevation-4">
      <v-card-title class="trip-title py-1">
        <v-row no-gutters>
          <v-col cols="8">
            <h4 class="primary--text title-container text-title-card" id="title-card">
                <v-icon class="green--text ml-4 mr-3" size="md">
                  fas fa-check
                </v-icon>
              {{ $t("components.views.trip.active.card.trip_no") }}
              {{ dataProp.tripNumber }}
            </h4>
          </v-col>
          <v-col cols="4 pr-4" align="right">
            <h4 class="green--text text--darken-2 pr-2 moneytext">
              {{ formatMoney(dataProp.amount, dataProp.currency) }}
            </h4>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider  class="mb-1 custom-divider" />
      <v-card-text class="pl-0 py-4 pr-4" >
        <v-container class="pa-0 ma-0">
          <v-row class="justify-center">

            <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="py-0 pl-0 pr-0 card-route" >
              <v-timeline align-top dense class="pa-0" >
              <v-timeline-item  color="secondary"  class="ma-0" small >
                <v-row class="pa-0">
                    <v-col class="stop-item">
                      <v-chip color="secondary" variant="flat" class="mb-2 card-chip">
                        <p  class="ma-0 pa-0 chip-text">{{ $t("components.views.trip.active.card.origin") }} </p>
                      </v-chip>
                      <v-row no-gutters class="mt-2">
                        <v-col cols="12" v-if="dataProp.origin">
                          <p class="mb-1 text">
                            <b>
                              <v-icon class="primary--text mr-2" size="small">
                                fa-solid fa-map-marker-alt
                              </v-icon>
                             <span class="texttitles">
                              {{ $t("components.views.trip.active.card.city") }}:</span></b>
                              <span class="route-text">
                                {{ originCity }}
                              </span>
                          </p>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <p id="address" class="mb-1 text" v-bind="attrs" v-on="on">
                                <b>
                                  <v-icon class="primary--text mr-2" size="small">
                                      fa-solid fa-map-marked-alt
                                  </v-icon>
                                  {{ $t("components.views.trip.active.card.address") }}:</b>
                                  <span class="route-text">
                                    {{ originAddress }}
                                  </span>
                              </p>
                            </template>
                            <span>{{ originAddressComplete }}</span>
                          </v-tooltip>
                          <p class="mb-1 text">
                            <b>
                              <v-icon class="primary--text mr-2" size="small">
                                fa-solid fa-calendar-alt
                              </v-icon>
                                {{ $t("components.views.trip.active.card.date") }}:</b>
                              <span class="route-text">
                                {{
                                    moment(dataProp.origin.schedule_date, "DD/MM/YYYY hh:mm:ss").format(
                                      "DD/MM/YYYY hh:mm:ss"
                                    )
                                  }}
                              </span>
                          </p>
                        </v-col>
                      </v-row>
                    </v-col>
                    </v-row>
              </v-timeline-item>

              <v-timeline-item color="secondary" small>
                  <v-row class="pt-1" >
                    <v-col class="stop-item">
                      <v-chip color="secondary" variant="flat" class="mb-2 card-chip">
                        <p class="ma-0 pa-0 chip-text">
                          {{ $t("components.views.trip.active.card.destiny") }}
                        </p>
                      </v-chip>
                      <v-row no-gutters class="mt-2">
                        <v-col cols="12" v-if="dataProp.destiny">
                          <p class="mb-1 text">
                            <b>
                              <v-icon class="primary--text mr-2" size="small">
                                fa-solid fa-map-marker-alt
                              </v-icon>
                              {{ $t("components.views.trip.active.card.city") }}:</b>
                              <span class="route-text">
                                {{ destinyCity }}
                              </span>
                          </p>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <p id="address" class="mb-1 text" v-bind="attrs" v-on="on">
                                <b>
                                  <v-icon class="primary--text mr-2" size="small">
                                      fa-solid fa-map-marked-alt
                                  </v-icon>
                                  {{ $t("components.views.trip.active.card.address") }}:</b>
                                  <span class="route-text">
                                    {{ destinyAddress }}
                                  </span>
                              </p>
                            </template>
                            <span>{{ destinyAddressComplete }}</span>
                          </v-tooltip>
                          <p class="mb-1 text">
                            <b>
                              <v-icon class="primary--text mr-2" size="small">
                                fa-solid fa-calendar-alt
                              </v-icon>
                              {{ $t("components.views.trip.active.card.date") }}:</b>
                            <span class="route-text">
                              {{
                                moment(dataProp.destiny.schedule_date, "DD/MM/YYYY hh:mm:ss").format(
                                  "DD/MM/YYYY hh:mm:ss"
                                )
                              }}
                            </span>
                          </p>
                        </v-col>
                      </v-row>
                    </v-col>
                    </v-row>
              </v-timeline-item>
            </v-timeline>
          </v-col>
          <!-- columna 2: info trip -->
          <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="mb-1   card-info" >
            <v-row class="justify-center py-0 mt-0">
              <v-col cols="12" class="pa-0 mt-0" >
                <div class="companyselected-container pb-2 pl-4">
                  <div class="companyselected-icon">
                    <v-icon class="primary--text mr-2" size="x-large">
                      fa-solid fa-building-circle-check
                    </v-icon>
                  </div>
                  <div class="companyselected-text">
                    <p class="pa-0 ma-0 text">
                      <b>
                        {{ $t("components.views.trip.active.card.assigned_company") }}
                      </b></br>
                      <span class="route-text pa-0 ma-0">
                        {{ nameTransporter }}
                      </span>
                    </p>
                  </div>
                </div>
                <v-divider  class="mb-3 custom-divider-company" />
                <!-- Conductor asignado -->
                <p class="text pl-4">
                  <b
                    > {{ $t("components.views.trip.active.card.assigned_driver") }}
                  </b></br>
                  <span class="route-text">
                    {{ nameDriver }}
                  </span>
                </p>
                <!-- Vehículo asignado -->
                <p class="text pl-4">
                  <b
                    >{{ $t("components.views.trip.active.card.assigned_vehicle") }}
                  </b> </br>
                  <span class="route-text">
                    {{ nameVehicle }}
                  </span>
                </p>
                <!-- Tipo de operación -->
                <p class="text pl-4">
                  <b
                    >{{ $t("components.views.trip.active.card.type_operation") }}
                  </b> </br>
                  <span class="route-text">
                    {{ dataProp.nameTypeOperation }}
                  </span>
                </p>
                <v-row class="justify-center"  v-if="dataProp.amountConfirmed > 0">
                  <v-col cols="12" class="py-2 my-0">
                    <p class="my-0 py-0 text">
                      <b>{{ $t("components.views.trip.active.card.accepted_value") }} </b>
                      <v-chip color="green" variant="flat" class="mb-2 mt-1" style="color: white;">
                        <h3> {{ formatMoney(dataProp.amountConfirmed, dataProp.currency) }}</h3>
                      </v-chip>
                    </p>
                  </v-col>
                </v-row>
              </v-col>
          </v-row>
        </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider  class="custom-divider" />

<v-card-actions class="card-btn-actions pa-0 ma-0">
  <v-container class="ma-0 pa-0">
    <v-row class="justify-center ma-0 pa-0 btn-container">
      <v-col class="btn-item ma-0 pa-0" cols="12">
        <v-btn
                variant="outlined"
                color="blue-darken-4"
                class="card-btn"
                dense
                @click="onOpenModalInfoOffer"
              >
                <v-icon class="mr-2" small>fa-solid fa-circle-info</v-icon>
                {{ $t("components.views.trip.active.card.detail") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>

</v-card-actions>
</v-card>

<v-dialog v-model="dialogInfoOffer" width="900px" persistent>
      <ModalDetails
        @closeForm="onCloseModalInfoOffer"
        :idMonitoringOrder="dataProp.idMonitoringOrder"
        :type="dataProp.type"
        :key="index"
      />
    </v-dialog>
  </v-col>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    ModalDetails: () =>
      import(
        /* webpackChunkName: "ModalDetails" */ "@/components/views/Trip/Active/modals/ModalDetails.vue"
      ),
  },
  data() {
    return {
      index: 0,
      dialogInfoOffer: false,
    };
  },
  computed: {
    dataProp: {
      get() {
        return this.data;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    title() {
      let val = this.$vuetify.breakpoint.lg ? 10 : this.$vuetify.breakpoint.xl ? 20 : 10,
        title = this.dataProp.tripNumber;
      return title.length > val ? title.substr(0, val) + " …" : title;
    },
    destinyCity() {
      if (this.dataProp.destiny == null) {
        return "";
      }
      if (this.dataProp.destiny.city == null) {
        return "";
      }
      return this.dataProp.destiny.city;
    },
    originCity() {
      if (this.dataProp.origin == null) {
        return "";
      }
      if (this.dataProp.origin.city == null) {
        return "";
      }
      return this.dataProp.origin.city;
    },
    destinyAddress() {
      if (this.dataProp.destiny == null) {
        return "";
      }
      if (this.dataProp.destiny.address == null) {
        return "";
      }
      let address = this.dataProp.destiny.address;
      let val = this.$vuetify.breakpoint.lg ? 40 : this.$vuetify.breakpoint.xl ? 50 : 40;
      return address.length > val ? address.substr(0, val) + " …" : address;
    },
    originAddress() {
      if (this.dataProp.origin == null) {
        return "";
      }
      if (this.dataProp.origin.address == null) {
        return "";
      }
      let address = this.dataProp.origin.address;
      let val = this.$vuetify.breakpoint.lg ? 40 : this.$vuetify.breakpoint.xl ? 50 : 40;
      return address.length > val ? address.substr(0, val) + " …" : address;
    },

    destinyAddressComplete() {
      if (this.dataProp.destiny == null) {
        return "";
      }
      if (this.dataProp.destiny.address == null) {
        return "";
      }
      return this.dataProp.destiny.address;
    },
    originAddressComplete() {
      if (this.dataProp.origin == null) {
        return "";
      }
      if (this.dataProp.origin.address == null) {
        return "";
      }
      return this.dataProp.origin.address;
    },
    nameTransporter() {
      return this.dataProp.nameTransportCompany || this.transportAssigned;
    },
    nameDriver() {
      return this.dataProp.driver ? this.dataProp.driver.name : "";
    },
    nameVehicle() {
      return this.dataProp.licensePlate || this.vehicleAssigned;
    },
  },
  methods: {
    onOpenModalInfoOffer() {
      this.index += 1;
      this.dialogInfoOffer = true;
    },
    onCloseModalInfoOffer() {
      this.dialogInfoOffer = false;
    },
  },
};
</script>


<style scoped>
@import './CardStyle.scss';

.v-timeline::before {
  background-color: #F05A24;
}

div.v-timeline-item__divider {
    position: relative;
    min-width: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F05A24;
}

.stop-item {
  padding: 1.2em 0 0 0;
  position: relative;
  left: -10px;
}


.card-chip {
  height: 18px;
}

.chip-text {
  font-size: 12px;
}


    /* Pantallas  grandes (1300px o más) */
    @media (min-width: 1300px) {
      .chip-text {
        font-size: 15px;
      }
      .card-chip {
        height: 20px;
      }

    }

    /* Pantallas extra grandes (1600px o más) */
    @media (min-width: 1600px) {
      .chip-text {
        font-size: 15px;
      }
      .card-chip {
        height: 20px;
      }

    }

</style>
